/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://replicant.us/"
  }, React.createElement(_components.img, {
    src: "images/banner.png",
    alt: "Replicant",
    title: "Replicant"
  }))), "\n", React.createElement(_components.p, null, "Android es un sistema operativo para multitud de dispositivos basado en un nucleo linux, hasta ahí todo bien pero resulta que está desarrollado y controlado por Google y tiene muchos componentes no libres, tanto en el propio sistema operativo como en su SDK. De todas formas Android es un SO con muchas posibilidades y por este motivo se han desarrollado proyectos derivados de Android que tratan de substituir sus componentes propietarios por componentes libres, principalmente con licencias GPL."), "\n", React.createElement(_components.p, null, "Hay dos de estos proyectos, ", React.createElement(_components.a, {
    href: "http://replicant.us/"
  }, "Replicant"), " y ", React.createElement(_components.a, {
    href: "https://code.google.com/p/open-android-alliance/"
  }, "Open Android Alliance"), ", para más información podeis leer también ", React.createElement(_components.a, {
    href: "https://elsoftwarelibre.wordpress.com/2011/05/25/replicant-open-android-alliance-alternativas-100-libres-a-android/"
  }, "esta entrada"), " del blog ", React.createElement(_components.a, {
    href: "https://elsoftwarelibre.wordpress.com/"
  }, "Soft-Libre"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
